.main {
	width: 100%;
}
.menu {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}
.link {
	margin-right: 20px;
	cursor: pointer;
	color: #888888;
}
.activeLink {
	margin-right: 20px;
	cursor: pointer;
	color: #000000;
}
.testBlock {
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
}
.overTestBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}

.uploadFile {
	.testBlock {
		display: block;
		cursor: pointer;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
		height: 25px;
	}
	.overTestBlock {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.hiddenInput {
		display: none;
	}
}