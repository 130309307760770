.main {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	overflow-y: auto;
	background-color: #FFFFFF;
	border: 1px solid #000000;

	.blockBorder {
		margin: 20px 0;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
	}

	.close {
		cursor: pointer;
		position: fixed;
		font-size: 40px;
		font-weight: 900;
		top: 15px;
		right: 20px;
	}
	
	.contentBlocks {
		width: 90%;
		margin: 10px auto;
		

		> div {
			margin: 10px 0;
		}
	}

	.openPartBlock {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 20pt;
		cursor: pointer;

		> div {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
		}

		.openCloseIcon {
			cursor: pointer;
			padding: 0 20px;
			font-size: 24pt;
		}
	}
}

.menu {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}
.link {
	margin-right: 20px;
	cursor: pointer;
	color: #888888;
}
.activeLink {
	margin-right: 20px;
	cursor: pointer;
	color: #000000;
}

.name {
	border: 1px solid rgb(4, 84, 4);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	height: 20px;
}
.hideBtn {
	border: 1px solid #888888;
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	height: 20px;
	margin-left: 20px;
	color: #888888;
}
.hiddenBtn {
	border: 1px solid red;
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	height: 20px;
	margin-left: 20px;
	color: red;
}
.overName {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.line {
	font-size: 12pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.lineActive {
	font-size: 12pt;
	font-weight: 700;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,255,0,0.1);
}
.lineNotActive {
	font-size: 12pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.forValue {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	> div {
		
		text-align: justify;
	}
}
.toggleHidden {
	cursor: pointer;
	font-size: 12pt;
	margin-right: 5px;
	font-weight: 700;
	border: 1px solid rgb(242, 236, 236);
	border-radius: 6px;
	padding: 0 3px;
}
.descriptionContent {
	width: 100%;
	font-size: 11pt;
	font-style: italic;
	color: #444444;
	padding-left: 20px;
}

.overNameValue {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	.value {
		width: 400px;
		margin-left: 25px;
		text-align: right;
	}
}