.main {
	padding: 20px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.logo {
		width: 200px;
		min-width: 200px;
		max-width: 200px;
	}

	.content {
		flex-grow: 3;

		.contentTitle {
			margin-left: 20px;
			margin-bottom: 20px;
			font-size: 22pt;
		}

		.contentBrief {
			margin-left: 15px;
			margin-top: 40px;
			font-size: 14pt;
			font-style: italic;
			text-align: justify;
		}

		.upload {
			padding: 20px 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			font-size: 18pt;
			font-weight: 900;
			color: red;
		}
	}

	input[type='file'] {
		display: none;
	}

	.customUploadBtn {
		cursor: pointer;
		color: #2c007d;
		border: 1px solid #9084e6;
		border-radius: 5px;
		display: inline-block;
		padding: 6px 12px;
	}
}

.propLine {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14pt;
	padding: 3px 5px;
	margin: 3px 0;

	border-bottom: 1px solid #888888;

	.propLineName {
		font-weight: 700;
		color:rgb(18, 2, 55);

		padding-left: 5px;

		min-width: 19%;
		max-width: 19%;
	}

	.propLineValueBlock {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		padding-right: 5px;

		border-left: 1px solid #888888;

		min-width: 19%;
		max-width: 19%;
	}
}