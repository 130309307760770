.main {
	width: 90%;
	margin: 10px auto;
}

.mainFilter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 3px 0 10px 0;
	padding: 5px;

	> div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.first {
		width: 90px;
		text-align: center;

		// input {
		// 	width: 40px;
		// }
	}

	.last {
		width: 130px;
		text-align: center;

		span {
			cursor: pointer;
			color: rgb(39, 5, 94);
		}

		span:hover {
			color: rgb(21, 1, 53);
		}
	}

	.hideCount {
		width: 50px;
		text-align: center;
	}

	.name {
		width: 200px;

		// input {
		// 	width: 120px;
		// }
	}

	.blocks {
		flex-grow: 2;
		// padding-left: 30px;

		// input {
		// 	width: 120px;
		// }
	}

	input {
		outline: none;
		font-size: 16pt;
		width: calc(100% - 20px);
	}
}
