.main {
	border: 1px solid rgb(221, 209, 209);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 3px 0;
	padding: 5px;

	font-size: 16pt;

	> div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.first {
		width: 90px;
		min-width: 90px;
		max-width: 90px;
		text-align: center;
	}

	.last {
		width: 130px;
		min-width: 130px;
		max-width: 130px;
		text-align: center;

		span {
			cursor: pointer;
			color: rgb(39, 5, 94);
		}

		span:hover {
			color: rgb(21, 1, 53);
		}
	}

	.hideCount {
		width: 50px;
		text-align: center;
		color: red;
	}

	.name {
		width: 200px;
		min-width: 200px;
		max-width: 200px;
	}

	.blocks {
		flex-grow: 2;
		padding-left: 30px;
	}
}