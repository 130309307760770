.main {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	overflow-y: auto;
	background-color: #FFFFFF;
	border: 1px solid #000000;

	.blockBorder {
		margin: 20px 0;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
	}

	.close {
		cursor: pointer;
		position: fixed;
		font-size: 40px;
		font-weight: 900;
		top: 15px;
		right: 20px;
	}
	
	.contentBlocks {
		width: 90%;
		margin: 10px auto;
		

		> div {
			margin: 10px 0;
		}
	}
}
.fieldName {
	padding-top: 100px;
	font-size: 16pt;
	font-weight: 900;
}
.textArea {
	margin-top: 20px;
	width: 100%;
	font-size: 16pt;
}
.btnsBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px 0;
	> div {
		cursor: pointer;
		border: 1px solid #888888;
		border-radius: 5px;
		padding: 0 10px;
		font-size: 16pt;
	}
}
.errorInfo {
	min-width: 30px;
	color: red;
	font-size: 16pt;
}