.main {
	width: 96%;
	margin: 10px auto;
}
.menu {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}
.link {
	margin-right: 20px;
	cursor: pointer;
	color: #888888;
}
.activeLink {
	margin-right: 20px;
	cursor: pointer;
	color: #000000;
}

.base {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.content {
		width: calc(100% - 320px);
		padding: 20px;
	}
	.menuBlock {
		width: 230px;
		padding: 20px;
		font-size: 15pt;
		.active {
			border: 1px solid #fcfafa;
			cursor: pointer;
			color: #280161;
			padding: 5px;
		}
		.noActive {
			// border: 1px solid #fcfafa;
			background-color: #ffffff;
			color: #888888;
			padding: 5px;
			font-weight: 700;
		}
	}
}


// .main {
// 	width: 98%;
// 	margin: 10px auto;
// }
// .user {
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: space-between;
// 	margin-top: 5px;

// 	> div {
// 		border: 1px solid #cdcdcd;
// 		align-items: center;
// 		text-align: center;
// 	}
// }
// .userNum {
// 	width: 3%;
// }
// .userEmail {
// 	width: 25%;
// }
// .userPhone {
// 	width: 25%;
// }
// .userProvider {
// 	width: 15%;
// }
// .userVerification {
// 	width: 15%;
// }
// .userAction {
// 	width: 15%;
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: space-between;
// 	> div {
// 		width: 48%;
// 		text-align: center;
// 	}
// }

// table {
// 	th, td {
// 		padding: 2px 10px;
// 		border: 1px solid #888888;
// 	}
// }

// .userLine {
// 	cursor: pointer;
// }
// .userLine:hower {
// 	background-color: beige;
// }
