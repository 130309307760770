.main {
	width: 96%;
	margin: 10px auto;
}
.formulaBlock {
	padding: 30px 20px;
}
.formulaTextarea {
	width: 100%;
	font-size: 16pt;
}
.label {
	margin-top: 15px;
}
.testBlock {
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
}
.overTestBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}

.forTicker {
	margin: 60px;
	background-color: rgba(0,32,0,0.02);
}
.lineActive {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 10pt;
	font-weight: 700;
	padding: 4px 10px;
	margin: 5px;
	background-color: rgba(0,255,0,0.1);
}
.tickerName {
	padding: 10px;
	font-size: 18px;
	font-weight: 900;
}
.result {
	text-align: right;
	padding: 10px;
	font-size: 18px;
	font-weight: 900;
}

.mainTest {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	overflow-y: auto;
	background-color: #FFFFFF;
	border: 1px solid #000000;

	.blockBorder {
		margin: 20px 0;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
	}

	.close {
		cursor: pointer;
		position: fixed;
		font-size: 40px;
		font-weight: 900;
		top: 15px;
		right: 20px;
	}
	
	.contentBlocks {
		width: 90%;
		margin: 10px auto;
		

		> div {
			margin: 10px 0;
		}
	}
}
.tree {
    overflow: auto;
	.name {
		border: 1px solid rgb(4, 84, 4);
		border-radius: 3px;
		padding: 0 5px;
		cursor: pointer;
	}
	.lineNotActive {
		font-size: 12pt;
		padding: 4px 10px;
		margin-top: 5px;
		margin-bottom: 5px;
		background-color: rgba(0,32,0,0.02);
	}
	.forValue {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		> div {
			
			text-align: justify;
		}
	}
	.overDescription {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.descriptionContent {
		width: 100%;
		font-size: 11pt;
		font-style: italic;
		color: #444444;
		padding-left: 20px;
	}
	.descriptionContentRight {
		width: 100%;
		font-size: 11pt;
		font-style: italic;
		color: #444444;
		padding-right: 20px;
		text-align: right;
		margin-top: 8px;
		margin-bottom: 3px;
	}
}

.testModeActive {
	border: 1px solid rgb(4, 84, 4);
	color: rgb(4, 84, 4);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	margin-left: 20px;
}
.testModeNoActive {
	border: 1px solid rgba(4, 84, 4, 0.5);
	color: rgba(4, 84, 4, 0.5);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	margin-left: 20px;
}
.minHeight40 {
	height: 40px;
	min-height: 40px;
	max-height: 40px;
	width: 100%;
}
.overActions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 30px 0;
	.left {
		width: 50%;
	}
	.right {
		width: 50%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
.btnAction {
	display: inline-block;
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
}
.smalFormulaTextarea {
    width: calc(100% - 10px);
	font-size: 14pt;
}
.overVersionAction {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.addVersionBtn {
	position: absolute;
	right: 55px;
	border: 1px solid rgb(4, 84, 4);
	color: rgb(4, 84, 4);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	margin-top: 3px;
}
.toggleHidden {
	cursor: pointer;
	font-size: 12pt;
	margin-right: 5px;
	font-weight: 700;
	border: 1px solid rgb(242, 236, 236);
	border-radius: 6px;
	padding: 0 3px;
}
