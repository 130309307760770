.main {}

.overTestBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}

.textRight {
	display: flex;
    justify-content: space-around;
}

.testBlock {
    display: inline-block;
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
	height: 25px;
    min-width: 200px;
    text-align: center;
}