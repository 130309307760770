.main {
	display: flex;
	flex-direction: column;
	width: 260px;
	margin: 0 auto;

	.titleBlock {
		min-height: 60px;
	}

	.inputBlock {
		margin-top: 15px;

		input {
			box-sizing: border-box;
			width: 260px;
			font-size: 20px;
			padding: 5px;
			outline: none;
			border-radius: 5px;
		}
	}

	.errorBlock {
		margin-top: 25px;
		min-height: 30px;
		color: red;
	}

	.buttonBlock {
		margin-top: 25px;

		button {
			box-sizing: border-box;
			width: 260px;
			margin: 0;
			font-size: 24px;
			padding: 5px;
			border: 1px solid #000000;
			border-radius: 5px;
			color: #000000;
			font-weight: 900;
			cursor: pointer;
		}
	}
}