.main {
	margin-bottom: 20px;
}
.name {
	border: 1px solid rgb(4, 84, 4);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
}
.line {
	font-size: 12pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.lineActive {
	font-size: 12pt;
	font-weight: 700;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,255,0,0.1);
}
.lineNotActive {
	font-size: 12pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.forValue {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	> div {
		
		text-align: justify;
	}
}
.toggleHidden {
	cursor: pointer;
	font-size: 12pt;
	margin-right: 5px;
	font-weight: 700;
	border: 1px solid rgb(242, 236, 236);
	border-radius: 6px;
	padding: 0 3px;
}
.descriptionContent {
	width: 100%;
	font-size: 11pt;
	font-style: italic;
	color: #444444;
	padding-left: 20px;
}