.main {}

.testModeActive {
	display: inline-block;
	border: 1px solid rgb(4, 84, 4);
	color: rgb(4, 84, 4);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	margin: 0 10px 5px 0;
}
.testModeNoActive {
	display: inline-block;
	border: 1px solid rgba(4, 84, 4, 0.5);
	color: rgba(4, 84, 4, 0.5);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
	margin: 0 10px 5px 0;
}

.overTable {
	width: 100%;
	overflow: scroll;
}

.activeSort {
	cursor: pointer;
	color: rgb(4, 84, 4);
	margin-left: 3px;
}

.notActiveSort {
	cursor: pointer;
	color: rgba(4, 84, 4, 0.5);
	margin-left: 3px;
}

.mainFilter {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 3px 0 10px 0;
	padding: 5px;

	> div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	input {
		outline: none;
		font-size: 16pt;
		width: calc(100% - 20px);
	}
}
.overMainFilterLabel {
	margin-top: 5px;
	margin-right: 10px;
}
.mainFilterLabel {
	font-size: 18px;
}

.overOverSearchBlock {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.overSearchBlock {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	border: 1px solid #ffffff;

	> div {
		width: 33%;
		padding: 3px 5px;
		border: 1px solid #888888;
		text-overflow: ellipsis;
	}
}

.backToSearchList {
	border: 2px solid #000000;
	cursor: pointer;
	display: block;
	position: relative;
	top: -15px;
	left: -15px;
	width: 30px;
	height: 30px;
	background-color: #ffffff;
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	span {
		display: block;
		font-size: 20px;
		font-weight: 900;
	}
}
.backToSearchList:hover {
	border: 2px solid #000000;
}

.overSearchBlock:hover {
	border: 1px solid #000000;
}

.showTutorialBlock {
	cursor: pointer;
	padding: 10px;
	color: #280161;
	// border: 1px solid #888888;
	// border-radius: 5px;
}
.overTestBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}

.smallBtn {
    margin-top: 1px;
	margin-left: 10px;
	cursor: pointer;
	padding: 5px 20px 5px 20px;
	border: 1px solid #888888;
	border-radius: 5px;
	height: 18px;
}

.overChoseFpCheckbox {
	// width: 100%;
	// display: flex;
	// flex-direction: row;
	// justify-content: space-between;
	// padding-left: 25px;
	min-height: 20px;
}

.overOverChoseFpCheckbox {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.choseFpCheckbox {
	// position: absolute;
}

.selectedFpValue {
	font-weight: 700;
}

.notSyncedValue {
	color: red;
	font-weight: 900;
}