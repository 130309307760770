.main {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
}
.topMenu {
	height: 60px;
	min-height: 60px;
	border-bottom: 1px solid #000000;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	> div {
		margin: 0;

		> * {
			margin: 0 15px;
		}
	}

	.activeProvider {
		cursor: pointer;
		color: #000000;
		border: 1px solid #000000;
		border-radius: 3px;
		padding: 2px 5px;
	}
	.notActiveProvider {
		cursor: pointer;
		color: #888888;
		border: 1px solid #888888;
		border-radius: 3px;
		padding: 2px 5px;
	}
}