.main {}
.overBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bigBtn {
	cursor: pointer;
	padding: 10px 20px 10px 20px;
	border: 1px solid #888888;
	border-radius: 5px;
	height: 25px;
}
.smallBtn {
    margin-top: 10px;
    margin-bottom: 10px;
	cursor: pointer;
	padding: 5px 20px 5px 20px;
	border: 1px solid #888888;
	border-radius: 5px;
	height: 18px;
}
.exampleImg {
    width: 100%;
    max-width: 200px;
    margin-bottom: 30px;
}
.width5percent {
    width: 5%;
    min-width: 100px;
}
.width15percent {width: 15%;}
.width25percent {width: 25%;}
.width30percent {width: 30%;}
.width45percent {width: 45%;}
.width50percent {width: 50%;}
.width60percent {width: 60%;}
.width70percent {width: 70%;}
.width80percent {width: 80%;}
.width90percent {
    width: 90%;

}

.width90percentRow {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.width90percentSubRow {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
}

.line {
	font-size: 12pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.blockLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.formulaTextarea {
	width: calc(100% - 50px);
	font-size: 16pt;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.formulaTextarea75px {
	width: calc(100% - 50px);
	font-size: 16pt;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 75px,
}
.smalFormulaTextarea {
    width: calc(100% - 10px);
	font-size: 14pt;
    margin-right: 10px;
}
.positionNumber {
    font-size: 20px;
    font-weight: 900;
    color: #888888;
}
.arrowIcon {
    width: 20px;
    height: 20px;
    // margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}
.label {
    padding-left: 5px;
    padding-right: 5px;
}