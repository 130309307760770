.main {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	overflow-y: auto;
	background-color: #FFFFFF;
	border: 1px solid #000000;

	.blockBorder {
		margin: 20px 0;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
	}

	.close {
		cursor: pointer;
		position: fixed;
		font-size: 40px;
		font-weight: 900;
		top: 15px;
		right: 20px;
	}
	
	.contentBlocks {
		width: 90%;
		margin: 10px auto;
		

		> div {
			margin: 10px 0;
		}
	}
}

.name {
	border: 1px solid rgb(4, 84, 4);
	border-radius: 3px;
	padding: 0 5px;
	cursor: pointer;
}
.line {
	font-size: 10pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.lineActive {
	font-size: 10pt;
	font-weight: 700;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,255,0,0.1);
}
.lineNotActive {
	font-size: 10pt;
	padding: 4px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: rgba(0,32,0,0.02);
}
.forTicker {
	margin-top: 60px;
	margin-bottom: 60px;
	background-color: rgba(0,32,0,0.02);
}
.forValue {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	> div {
		
		text-align: justify;
	}
}
.toggleHidden {
	cursor: pointer;
	font-size: 10pt;
	margin-right: 5px;
	font-weight: 700;
	border: 1px solid rgb(242, 236, 236);
	border-radius: 6px;
	padding: 0 3px;
}