.main {}
.formulaBlock {
	padding: 30px 20px;
}
.formulaTextarea {
	width: 100%;
	font-size: 16pt;
}
.label {
	margin-top: 15px;
}
.testBlock {
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
	height: 25px;
}
.overTestBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}

.forTicker {
	margin: 60px;
	background-color: rgba(0,32,0,0.02);
}
.lineActive {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 10pt;
	font-weight: 700;
	padding: 4px 10px;
	margin: 5px;
	background-color: rgba(0,255,0,0.1);
}
.tickerName {
	padding: 10px;
	font-size: 18px;
	font-weight: 900;
}
.result {
	text-align: right;
	padding: 10px;
	font-size: 18px;
	font-weight: 900;
}

.mainTest {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	overflow-y: auto;
	background-color: #FFFFFF;
	border: 1px solid #000000;

	.blockBorder {
		margin: 20px 0;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
	}

	.close {
		cursor: pointer;
		position: fixed;
		font-size: 40px;
		font-weight: 900;
		top: 15px;
		right: 20px;
	}
	
	.contentBlocks {
		width: 90%;
		margin: 10px auto;
		

		> div {
			margin: 10px 0;
		}
	}
}
.tree {
	.name {
		border: 1px solid rgb(4, 84, 4);
		border-radius: 3px;
		padding: 0 5px;
		cursor: pointer;
	}
	.lineNotActive {
		font-size: 12pt;
		padding: 4px 10px;
		margin-top: 5px;
		margin-bottom: 5px;
		background-color: rgba(0,32,0,0.02);
	}
	.forValue {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		> div {
			
			text-align: justify;
		}
	}
	.overDescription {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.descriptionContent {
		width: 100%;
		font-size: 11pt;
		font-style: italic;
		color: #444444;
		padding-left: 20px;
	}
}

.textRight {
	text-align: right;
}

.activeSpanBtn {
	cursor: pointer;
	padding: 0 10px;
	margin: 0 10px;
	border: 1px solid #000000;
	border-radius: 5px;
	color: #000000;
}
.noActiveSpanBtn {
	cursor: pointer;
	padding: 0 10px;
	margin: 0 10px;
	border: 1px solid #888888;
	border-radius: 5px;
	color: #888888;
}

.selectSize {
	font-size: 16pt;
}

.overCheckboxes > div {
	display: inline-block;

	label {
		margin-right: 30px;
		font-size: 16pt;
	}
}

.overSortAndHideUiBlocks {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	> div {
		margin-right: 50px;
	}
}

.decorativBorder {
	border: 1px solid #888888;
	border-radius: 5px;
	margin: 20px 0;
	padding: 10px 20px;
	background-color: #FCFCFC;
}

.overCompareBlocks {
	display: flex;
	flex-direction: row;
	justify-content: space-between
}

.compareBlock10 {
	width: 10%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.compareBlock40 {
	width: 40%;
}
.compareBlock48 {
	width: 48%;
}