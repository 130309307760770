.main {
	width: 100%;
}
.menu {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}
.link {
	margin-right: 20px;
	cursor: pointer;
	color: #888888;
}
.activeLink {
	margin-right: 20px;
	cursor: pointer;
	color: #000000;
}
.testBlock {
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
}
.overTestBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}

.uploadFile {
	.testBlock {
		display: block;
		cursor: pointer;
		padding: 10px;
		border: 1px solid #888888;
		border-radius: 5px;
		height: 25px;
	}
	.overTestBlock {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.hiddenInput {
		display: none;
	}
}

.selectSize {
	font-size: 16pt;
}

.btnBlock {
	margin: 20px 0;
	text-align: right;
}
.btnAction {
	display: inline-block;
	cursor: pointer;
	padding: 10px;
	border: 1px solid #888888;
	border-radius: 5px;
}
.overTable {
	width: 100%;
	overflow: scroll;
}
.symbolPoint {
	background-color: rgb(136, 242, 207);
}
.checkedPoint {
	background-color: rgb(205, 253, 237);
}
.emptyPoint {
	background-color: rgb(243, 209, 230);
}
.nullPoint {
	background-color: rgb(255, 255, 255);
	font-weight: 900;
	color: blue;
}
.overActions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 30px 0;
	.left {
		width: 50%;
	}
	.right {
		width: 50%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
.userLine {
	cursor: pointer;
}
.userLine:hower {
	background-color: beige;
}
