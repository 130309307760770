.main {
	width: 96%;
	margin: 10px auto;
}
.menu {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}
.link {
	margin-right: 20px;
	cursor: pointer;
	color: #888888;
}
.activeLink {
	margin-right: 20px;
	cursor: pointer;
	color: #000000;
}

.base {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.content {
		width: calc(100% - 320px);
		padding: 20px;
	}
	.menuBlock {
		width: 230px;
		padding: 20px;
		font-size: 15pt;
		.active {
			border: 1px solid #fcfafa;
			cursor: pointer;
			color: #280161;
			padding: 5px;
		}
		.noActive {
			// border: 1px solid #fcfafa;
			background-color: #ffffff;
			color: #888888;
			padding: 5px;
			font-weight: 700;
		}
	}
}
